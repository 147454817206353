import { ContactIcon, HomeIcon, LineChartIcon, PanelLeft, Users2Icon } from 'lucide-react'
import { Outlet, useNavigate } from 'react-router-dom'

import { AvatarWithTooltip } from '@/components/ui/avatar-with-tooltip'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
} from '@/components/ui/breadcrumb'
import { Button } from '@/components/ui/button'
import { Link } from '@/components/ui/link'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { useLogOut } from '@/hooks/useLogOut'
import { auth } from '@/lib/auth'
import { useSession } from '@/lib/auth/useSession'
import { toastError } from '@/utils/error'

interface CommonLayout {
  label?: string
  route?: string
}

const NavigationConfig = [
  {
    path: '/kanban-board',
    icon: <HomeIcon className="h-5 w-5" />,
    label: 'Kanban board',
    admin: false,
  },
  {
    path: '/user-management',
    icon: <Users2Icon className="h-5 w-5" />,
    label: 'User management',
    admin: true,
  },
  {
    path: '/client-management',
    icon: <ContactIcon className="h-5 w-5" />,
    label: 'Client management',
    admin: true,
  },
  {
    path: '/dashboard',
    icon: <LineChartIcon className="h-5 w-5" />,
    label: 'Dashboard',
    admin: false,
  },
]

export default function CommonLayout({ label, route }: CommonLayout) {
  const [session] = useSession()
  const navigate = useNavigate()
  const mutation = useLogOut()

  return (
    <div className="flex min-h-screen w-full flex-col bg-muted/40">
      <aside className="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex">
        <nav className="flex flex-col items-center gap-4 px-2 py-4">
          {/* <a
            href="/"
            className="group flex h-9 w-9 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:h-8 md:w-8 md:text-base"
          >
            <Package2 className="h-4 w-4 transition-all group-hover:scale-110" />
            <span className="sr-only">P2M Inc</span>
          </a> */}
          <div className="empty w-9 h-9"></div>
          {NavigationConfig.map((navigation) => {
            if (navigation.admin && !session?.user.is_admin) {
              return null
            }
            return (
              <Tooltip key={navigation.path}>
                <TooltipTrigger>
                  <Link
                    to={navigation.path}
                    className="p2m_nav-link flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8"
                    prefetch
                  >
                    {navigation.icon}
                    <span className="sr-only">{navigation.label}</span>
                  </Link>
                </TooltipTrigger>
                <TooltipContent side="right" sideOffset={14}>
                  {navigation.label}
                </TooltipContent>
              </Tooltip>
            )
          })}
        </nav>
      </aside>
      <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">
        <header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
          <Sheet>
            <SheetTrigger asChild>
              <Button size="icon" variant="outline" className="sm:hidden">
                <PanelLeft className="h-5 w-5" />
                <span className="sr-only">Toggle Menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="sm:max-w-xs">
              <nav className="grid gap-6 text-lg font-medium">
                {/* <a
                  href="/"
                  className="group flex h-10 w-10 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:text-base"
                >
                  <Package2 className="h-5 w-5 transition-all group-hover:scale-110" />
                  <span className="sr-only">P2M Inc</span>
                </a> */}
                <div className="empty h-10 w-10"></div>
                {NavigationConfig.map((navigation) => {
                  if (navigation.admin && !session?.user.is_admin) {
                    return null
                  }
                  return (
                    <Link
                      key={navigation.path}
                      to={navigation.path}
                      className="p2m_nav-link flex items-center gap-4 px-2.5 py-2 text-muted-foreground hover:text-foreground"
                      prefetch
                    >
                      {navigation.icon}
                      <span>{navigation.label}</span>
                    </Link>
                  )
                })}
              </nav>
            </SheetContent>
          </Sheet>
          <Breadcrumb className="hidden md:flex">
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <a href={route}>{label}</a>
                </BreadcrumbLink>
              </BreadcrumbItem>
              {/* <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <a href="#">Products</a>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>All Products</BreadcrumbPage>
              </BreadcrumbItem> */}
            </BreadcrumbList>
          </Breadcrumb>
          <div className="relative ml-auto flex-1 flex items-center justify-end space-x-1 md:grow-0">
            {/* <img
              src="/placeholder-user.jpg"
              width={36}
              height={36}
              alt="Avatar"
              className="overflow-hidden rounded-full"
            /> */}
            <AvatarWithTooltip name={session?.user.nick_name} />
            <Button
              variant="ghost"
              onClick={() => {
                mutation.mutate(undefined, {
                  onSuccess: () => {
                    auth.logout()
                    navigate('/login')
                  },
                  onError: toastError,
                })
              }}
            >
              Log out
            </Button>
            {/* <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  className="overflow-hidden rounded-full"
                ></Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>My Account</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem>Settings</DropdownMenuItem>
                <DropdownMenuItem>Support</DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem>Logout</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu> */}
          </div>
        </header>
        <main className="flex items-start gap-4 p-4 w-full h-[calc(100vh_-_80px)] sm:px-6 sm:py-0 md:gap-8">
          <Outlet />
        </main>
      </div>
    </div>
  )
}
